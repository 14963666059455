import { Button, Input, message, Space, Spin, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './staff-search.css';
import { PROMOTION_CODE_URL } from '../../api/URLs';
import { SearchOutlined } from '@mui/icons-material';
import { deleteDataRequest, getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import TableFetchData from '../../components/data-table/TableFetchData';
import { useTranslation } from 'react-i18next';
import EditTableSvg from '../../assets/images/EditTable';
import DeleteTableSvg from '../../assets/images/DeleteTable';
import { formatDateTime } from '../../utils/dateFormatUtils';
import DateRangeComponent from '../../components/date/DateRangeComponent';
import ProCodeModal from '../../components/ProCodeModal';


function PromotionCodePage() {
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [staffData, setStaffData] = useState([])
  const [searchResult, setSearchResult] = useState([]);
  const [modalOpenNew, setModalOpenNew] = useState(false)
  const messageModalRef = useRef('')
  const [keyword, setkeyword] = useState('');
  const [isSubmit, setIsSubmit] = useState(false)
  const [ id, setId] = useState('');
  const [dates, setDates] = useState([null, null]);
  const [dataObject, setDataObject] = useState({
    code: "",
    status: true
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const { t } = useTranslation()

  const tableStaffMangement = {
    appendable: false,
    removable: false,
    bordered: false,
    numbered: true,
    size: 'middle',
    pagination: {
      showLessItems: true,
      showSizeChanger: false,
      pageSize: 20,
    },
    columns: [
      {
        title: <label className="titleCard14400">{t('code')}</label>,
        render: (_, record) => {
          return (
            <label className="text-capitalize text-wrap-nowrap">{record && record.code}</label>
          )
        }
      },
      {
        title: <label className="ant-table-column-title titleCard14400">{t('Upload time')}</label>,
        dataIndex: 'createdTS',
        sorter: true,
        key: 'createdTS',
        render: (_, record) => (
          <label className="text-capitalize">{record && record.createdTS && formatDateTime(record.createdTS)}</label>
        )
      },
      {
        title: <label className="titleCard14400">{t('Upload account')}</label>,
        dataIndex: 'account',
        key: 'account',
        render: (_, record) => {
          return (
            <label className="text-capitalize text-wrap-nowrap">{record && record.createdBy && record.createdBy.name ? record.createdBy.name : ''}</label>
          )
        }
      },
      {
        title: <label className="titleCard14400">{t('Operator')}</label>,
        width: 120,
        render: (_, record) => {
          return (
            <Space size="middle">
              <Switch onChange={() => handleSwitch(record)} checked={record.status} />
              <span style={{ cursor: "pointer" }}
                onClick={() => {
                  setModalOpenNew(true)
                  setDataObject(record)
                  setId(record&& record.codeId)
                }}>
                <EditTableSvg />
              </span>
              <span style={{ cursor: "pointer" }}
                onClick={() => deleteStaffData(record)}>
                <DeleteTableSvg />
              </span>
            </Space>
          )
        },
      },
    ],
  };

  const formatStaffData = (staffData) => {
    let staffDataCopy = JSON.parse(JSON.stringify(staffData));
    staffDataCopy.forEach((staff) => {
      staff.name = staff.name;
    });
    setSearchResult(
      staffData.map((staff) => ({
        ...staff,
        code: staff.code || '',
        status: staff.status || '',
      }))
    );
  };

  useEffect(() => {
    filterTableResult('');
  }, [searchResult]);

  const filterTableResult = (value) => {
    const newStaffList = searchResult.filter((staff) => {
      return true
    });
    setStaffData(newStaffList);
  };

  // for list all staff
  const initLoadStaffData = async (value) => {
    setLoading(true);
    await getDataRequest(`${PROMOTION_CODE_URL}`, {
      pageNo: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      keyword: value ? value.trim() : '',
      startDate: dates[0] ? dates[0] : '',
      endDate: dates[1] ? dates[1] : '',
      sortKeys: { [tableParams.sortField]: tableParams.sortOrder === "ascend" ? "asc" : "desc" }
    }).then((res) => {
      if (res.status === 200) {
        formatStaffData(res?.data);
      }
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total,
        },
      });
      setLoading(false);
    }).catch((err) => {
      setLoading(false)
      console.log("Error", err)
    });
  };

  const handleUpdate = async (item) => {
    try {
      const memberPayload = {
        code: dataObject && dataObject.code,
      }
      setLoadingNew(true);
      await putDataRequest(`${PROMOTION_CODE_URL}/${item.codeId || id}`, memberPayload)
        .then((res) => {
          if (res.status === 200) {
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            initLoadStaffData();
            filterTableResult('');
            setModalOpenNew(false)
            setId("")
            setDataObject((data) => ({
              ...data,
              code: "",
            }));
            setTimeout(() => {
              setLoadingNew(false);
            }, 1000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoadingNew(false);
        })
        .catch((err) => {
          setLoadingNew(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        });
    } catch (error) {
      setLoadingNew(false);
      messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
    }
  }

  const handleSwitch = async (item) => {
    try {
      const memberPayload = {
        status: !item.status
      }
      setLoadingNew(true);
      await putDataRequest(`${PROMOTION_CODE_URL}/${item.codeId || id}`, memberPayload)
        .then((res) => {
          if (res.status === 200) {
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            initLoadStaffData();
            filterTableResult('');
            setModalOpenNew(false)
            setId("")
            setDataObject((data) => ({
              ...data,
              code: "",
            }));
            setTimeout(() => {
              setLoadingNew(false);
            }, 1000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoadingNew(false);
        })
        .catch((err) => {
          setLoadingNew(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        });
    } catch (error) {
      setLoadingNew(false);
      messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
    }
  }


  const deleteStaffData = async (item) => {
    messageModalRef.current.showWarningConfirm(t('Are you sure you want to delete this promotion code ?'), async () => {
      setLoadingNew(true)
      await deleteDataRequest(`${PROMOTION_CODE_URL}/${item.codeId}`).then((res) => {
        messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
        initLoadStaffData();
        setLoadingNew(false)
      }).catch((err) => {
        message.error("Error", err)
        setLoadingNew(false)
      });
    }, "", true);
  };
  const [isSearch, setIsSearch] = useState(true)
  useEffect(() => {
    isSearch && initLoadStaffData(keyword)
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),isSearch
  ]);

  const handelCreate = async () => {
    setIsSubmit(true);
    setLoadingNew(true);
    const memberPayload =  {
      code: dataObject && dataObject.code,
    }
    try {
      const res = await postDataRequest(PROMOTION_CODE_URL, memberPayload);
      if (res?.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { }, "", true);
        initLoadStaffData();
        setModalOpenNew(false)
        setDataObject((data) => ({
          ...data,
          code: "",
        }));
      } else {
        messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
      }
    } catch (err) {
      messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message || err && err.message, () => { }, "", true);
    } finally {
      setLoadingNew(false);
      setIsSubmit(false);
    }
  }

  return (
    <div className="container- box-shadow h-100">
      <div className="w-100 h-100 overflow-auto" style={{padding:"0px 25px"}}>
        <Spin spinning={loading}>
          <div className="w-100">
            <div className="d-flex justify-content-between mt-3 mb-3">
              <div className="d-flex align-items-center col-6 row">
                <div className='col-6'>
                  <Input
                    placeholder={t('employee_search')}
                    size="large"
                    disabled={loading}
                    allowClear
                    prefix={
                      <SearchOutlined
                        onClick={() => {
                        }}
                        style={{
                          color: '#8c8c8c',
                          cursor: "pointer",
                          opacity: "0.4"
                        }}
                      />
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setkeyword(value);
                    }}
                    onPressEnter={async (e) => {
                    }}
                  />
                </div>
                <div className='col-6'>
                  <DateRangeComponent value={dates ? dates : [null, null]} setValue={(value) => {
                    setDates(value)
                    setTableParams({
                      ...tableParams,
                      pagination: {
                        ...tableParams.pagination,
                        current: 1,
                        total: 0,
                      },
                    });
                  }} />
                </div>
              </div>
              <div className='d-flex alight-items-center'>
                <Button size={'large'}
                  onClick={() => {
                    setTableParams({
                      ...tableParams,
                      pagination: {
                        ...tableParams.pagination,
                        current: 1,
                        total: 0,
                      },
                    });
                    setIsSearch(false)
                    setTimeout(() => {
                      setIsSearch(true)
                    }, 100);
                    // initLoadStaffData(keyword);
                  }}
                >
                  {t('btnSearch')}
                </Button>
                <div className="me-2 ms-2 d-flex align-items-center" style={{ height: "100%", borderLeft: "1px solid rgba(220, 220, 220, 0.83)" }}></div>
                <Button className='btngoCreate' size={'large'}
                  // type="primary" 
                  onClick={() => {
                    setModalOpenNew(true)
                  }}
                >
                  {t('Create Promotion')}
                </Button>
              </div>
            </div>
          </div>

          <div className="w-100">
            <TableFetchData
              tableParams={tableParams} setTableParams={setTableParams}
              data={staffData}
              columns={tableStaffMangement.columns}
            />
          </div>
        </Spin>
      </div>
      <MessageConfirmModal textCentered ref={messageModalRef} />
      <ProCodeModal
         setModalOpenNew={setModalOpenNew} 
         setDataObject={setDataObject} 
         dataObject={dataObject} 
         isSubmit={isSubmit} 
         handleUpdate={handleUpdate} 
         handelCreate={handelCreate} 
         id={id} 
         setId={setId}
         modalOpenNew={modalOpenNew}
         loadingNew={loadingNew} 
         setLoadingNew={setLoadingNew}
      />
    </div>
  );
}

export default PromotionCodePage;
