import React from "react";
import InputComponent from "./input/InputComponent";
import { Button, Col, Modal, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";

const ProCodeModal = (props) => {
    const { setModalOpenNew, setDataObject, dataObject, isSubmit, handleUpdate, handelCreate, id, modalOpenNew, setId, loadingNew } = props
    const { t } = useTranslation()
    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginLeft: "10px" }}>{t('Promotion Management')}</span>
                </div>
            }
            centered
            width={700}
            open={modalOpenNew}
            className='custom-modal'
            onOk={() => {
                setModalOpenNew(false)
                setId("")
                setDataObject((data) => ({
                    ...data,
                    code: "",
                }));
            }}
            onCancel={() => {
                setModalOpenNew(false)
                setId("")
                setDataObject((data) => ({
                    ...data,
                    code: "",
                }));
            }}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: "none" } }}
        >
            <Spin spinning={loadingNew}>
                <Row className='mt-3 d-flex align-items-center' style={{ padding: "10px" }}>
                    <Col md={4}>
                        <div>
                            <label htmlFor=''>{t('code')}</label>
                        </div>
                    </Col>
                    <Col md={20} className='d-flex'>
                        <InputComponent
                            status={isSubmit && !dataObject.code && `error`}
                            value={dataObject.code}
                            setValue={(value) => setDataObject({ ...dataObject, code: value })}
                            placeholder={''}
                        />
                    </Col>
                </Row>
                <div className='text-center pt-3'>
                    <Button
                        style={{
                            backgroundColor: "#e50a47",
                            color: "white",
                            height: "45px",
                            width: "230px",
                            fontWeight: 500,
                            border: "none",
                            fontSize: "16px"
                        }}
                        onClick={id ? handleUpdate : handelCreate}
                    >
                        {id ? t('update') : t('Submit')}
                    </Button>
                </div>
            </Spin>
        </Modal>
    )
}
export default ProCodeModal;