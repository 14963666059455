import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './pages/authen-page/LoginPage';
import PasswordResetPage from './pages/authen-page/PasswordResetPage';
import SendPasswordResetInstructionPage from './pages/authen-page/SendPasswordResetInstructionPage';
import PageNotFound from './pages/not-found/NotFoundPage';
import RegisterPage from './pages/authen-page/RegisterPage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ApplicationRight } from './share/RightList';
import GuardedRoutes from './components/guarded-route/guarded-routes';
import { setTimezone } from './stores/timezone.store';
import PageNotAccess from './pages/not-access/PageNotAccess';
import HeaderComponent from './components/header/headerComponent';
import PaymentPage from './pages/payment-pages/PaymentPage';
import PaymentCreatePage from './pages/payment-pages/PaymentCreatePage';
import MemberShipPage from './pages/membership-pages/MemberShipPage';
import EducationCoursesPage from './pages/education-courses-pages/EducationCoursesPage';
import MagazinePage from './pages/magazine-pages/MagazinePage';
import ShoppingPage from './pages/shopping-pages/ShoppingPage';
import MemberShipCreatePage from './pages/membership-pages/MemberShipCreatePage';
import MagazineCreatePage from './pages/magazine-pages/MagazineCreatePage';
import EducationCoursesCreatePage from './pages/education-courses-pages/EducationCoursesCreatePage';
import ShoppingCreatePage from './pages/shopping-pages/ShoppingCreatePage';
import NewsPage from './pages/news-management-page/news-pages/NewsPage';
import NewCreatePage from './pages/news-management-page/news-pages/NewCreatePage';
import NewsPostsListPage from './pages/news-management-page/news-posts/child-pages/NewPostListPage';
import NewsPostCreateUpdatePage from './pages/news-management-page/news-posts/child-pages/NewPostCreateUpdatePage';
import NewsPostsPageMain from './pages/news-management-page/news-posts/NewsPostsPageMain';
import MemberCreatePage from './pages/member-pages/MemberCreatePage';
import MemberPage from './pages/member-pages/MemberPage';
import StaffSearchPage from './pages/staff/staff-search.page';
import StaffCreatePage from './pages/staff/staff-create.page';
import SystemSettingsContainerPage from './pages/system-settings/SystemSettingsContainerPage';
import RightsAndRolesPage from './pages/system-settings/child-pages/rights-and-roles/RightsAndRolesPage';
import { menus } from './components/navigations/menu';
import HeaderCreateComponent from './components/header/headerCreateComponent';
import { useTranslation } from 'react-i18next';
import UserDetailPage from './pages/member-pages/UserDetailPage';
import HeaderDetailComponent from './components/header/headerDetailComponent';
import MemberDetailPage from './pages/member-pages/MemberDetailPage';
import PaymentHistory from './pages/member-pages/PaymentHistory';
import MagazineUpload from './components/magazine/MagazineUpload';
import NewManagementPage from './pages/new-management-pages/NewManagementPage';
import HeaderDetailNewsComponent from './components/header/HeaderDetailNewsComponent';
import NewUpload from './components/newsManagemet/NewUpload';
import PromotionCodePage from './pages/promotion-code-management/promotion-code.page';

function App() {
  const user = useSelector((state) => state.authenticatedUser);
  const timezone = useSelector((state) => state.timezone);
  const [authenticated, setAuthenticated] = useState(true);
  const [modalOpen, setModalOpen] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (timezone) {
    // moment.tz.setDefault(timezone);
  }

  useEffect(() => {
    if (!user) {
      setAuthenticated(false);

      const allowPaths = ['/resetPassword', '/resetPasswordLink'];

      if (!allowPaths.includes(location.pathname)) {
        // navigate('/login');
      }
    } else {
      setAuthenticated(true);
    }
  }, [user]);


  // let token = user
  let token = localStorage.getItem("user")
  let menusAccess = user ? menus(user) : null
  const { t } = useTranslation();

  useEffect(() => {
    const allowPaths = [
      "/login",
      "/resetPasswordLink",
      "/resetPassword",
      "/register",
    ];
    if(!token) {
      if (!allowPaths.includes(location.pathname)) {
        navigate('/login');
      }
    }
    if(token) {
      if (allowPaths.includes(location.pathname)) {
        navigate('/');
      }
    }
  }, [token])

  return (
    <>
      <Routes>
        {/* <HeaderComponent pageTitle={t('User Management')} /> */}
        <Route element={!token ? <Navigate to="/login" /> : <><Outlet /></>}>
          <Route path="/" element={<Navigate to={menusAccess && menusAccess[0] && menusAccess[0].pathname ? menusAccess && menusAccess[0] && menusAccess[0].pathname : "/member"} />} />
          <Route element={<GuardedRoutes accessingRight={ApplicationRight.Member_Access} />}>
            <Route element={<HeaderComponent pageTitle={t('User Management')} />}>
              <Route path="/member" element={<MemberPage />} />
            </Route>
            <Route>
              <Route path="/user/details" element={<UserDetailPage modalOpen={modalOpen} setModalOpen={setModalOpen}/>} />
            </Route>
            <Route>
              <Route path="/membership/details" element={<PaymentHistory modalOpen={modalOpen} setModalOpen={setModalOpen}/>} />
            </Route>
            <Route>
              <Route path="/member/details" element={<MemberDetailPage />} />
            </Route>
          </Route>
          <Route element={<GuardedRoutes accessingRight={ApplicationRight.Payment_Access} />}>
            <Route element={<HeaderComponent pageTitle={t('Payment Management')} />}>
              <Route path="/payment" element={<PaymentPage />} />
            </Route>
            <Route element={<HeaderCreateComponent isHide="view" />}>
              <Route path="/payment/view" element={<PaymentCreatePage />} />
            </Route>
          </Route>
          {/* <Route element={<GuardedRoutes accessingRight={ApplicationRight.Staff_Management_Access} />}>
            <Route path="/membership" element={<MemberShipPage />} />
            <Route path="/membership/view" element={<MemberShipCreatePage />} />
          </Route> */}
          {/* <Route element={<GuardedRoutes accessingRight={ApplicationRight.Staff_Management_Access} />}>
            <Route path="/education-courses" element={<EducationCoursesPage />} />
            <Route path="/education-courses/create" element={<EducationCoursesCreatePage />} />
          </Route> */}
          <Route element={<GuardedRoutes accessingRight={ApplicationRight.Magazine_Access} />}>
            <Route element={<HeaderComponent pageTitle={t('Magazine Management')} />}>
              <Route path="/magazine" element={<MagazinePage />} />
            </Route>
            <Route element={<HeaderComponent pageTitle={t('Magazine Management')} isHide="view" />}>
              <Route path="/magazine/create" element={<MagazineCreatePage />} />
            </Route>
            <Route path="/magazine/upload/:id?" element={<MagazineUpload />} />
          </Route>
          <Route element={<GuardedRoutes accessingRight={ApplicationRight.News_Posts_Access} />}>
            <Route element={<HeaderComponent pageTitle={t('New Management')} />}>
              <Route path="/news-posts" element={<NewManagementPage />} />
            </Route>
            {/* <Route element={<HeaderComponent pageTitle={t('Magazine Management')} isHide="view" />}>
              <Route path="/magazine/create" element={<MagazineCreatePage />} />
            </Route> */}
            <Route>
              <Route path="/news/upload/:newStep?" element={<NewUpload />} />
            </Route>
          </Route>
          <Route element={<GuardedRoutes accessingRight={ApplicationRight.Promotion_Access} />}>
            <Route element={<HeaderComponent pageTitle={t('Promotion Management')} />}>
              <Route path="/promotion" element={<PromotionCodePage />} />
            </Route>
            {/* <Route>
              <Route path="staff/create" element={<StaffCreatePage />} />
            </Route> */}
          </Route>
          {/* <Route element={<GuardedRoutes accessingRight={ApplicationRight.Staff_Management_Access} />}>
            <Route path="/shopping" element={<ShoppingPage />} />
            <Route path="/shopping/view" element={<ShoppingCreatePage />} />
          </Route> */}
          {/* <Route element={<GuardedRoutes accessingRight={ApplicationRight.News_Posts_Access} />}>
          <Route element={<HeaderComponent pageTitle={t('New Management')} />}>
            <Route path="/news-posts" element={<NewsPostsPageMain />}>
              <Route path="" element={<NewsPostsListPage />} />
              <Route path="create-new" element={<NewsPostCreateUpdatePage compose />} />
              <Route path="edit/:postId" element={<NewsPostCreateUpdatePage edit />} />
              <Route path="templating-from/:postId" element={<NewsPostCreateUpdatePage template />} />
            </Route>
          </Route>
          </Route> */}
          <Route element={<GuardedRoutes accessingRight={ApplicationRight.Staff_Management_Access} />}>
            <Route element={<HeaderComponent pageTitle={t('Staff Management')} />}>
              <Route path="staff/search" element={<StaffSearchPage />} />
            </Route>
            <Route>
              <Route path="staff/create" element={<StaffCreatePage />} />
            </Route>
          </Route>
          {/* <Route element={<GuardedRoutes accessingRight={ApplicationRight.Staff_Management_Access} />}>
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/create" element={<NewCreatePage />} />
          </Route> */}
          {/* <Route element={<GuardedRoutes accessingRight={ApplicationRight.System_Setting_Access} />}>
            <Route element={<HeaderComponent pageTitle={t('System Management')} />}>
              <Route path="/system-settings/" element={<SystemSettingsContainerPage />}>
                <Route path="rights-and-roles" element={<RightsAndRolesPage />} />
              </Route>
            </Route>
          </Route> */}
        </Route>

        <Route element={token ? <Navigate to="/" /> : <><Outlet /></>}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/resetPasswordLink" element={<SendPasswordResetInstructionPage />} />
          <Route path="/resetPassword" element={<PasswordResetPage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Route>

        <Route>
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

      </Routes>
    </>
  );
}

export default App;

