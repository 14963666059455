export const permissionsHave = () => {
  const accesshave = [
    {
      label: "User Management",
      value: "用户管理"
    },
    {
      label: "Payment Management",
      value: "收款管理"
    },
    {
      label: "Magazine Management",
      value: "杂志管理"
    },
    {
      label: "New Management",
      value: "资讯管理"
    },
    {
      label: "Promotion Management",
      value: "促销管理"
    },
    {
      label: "Staff Management",
      value: "账户管理"
    },
  ]
  return accesshave
}