import * as React from 'react';

const ProCodeSvgActive = () => {
    return (
        <svg fill="#e0005a" height="19px" width="19px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <g>
                <g>
                    <g>
                        <path  stroke="#e0005a" strokeWidth="20" d="M412.626,290.038c-3.483,2.793-4.042,7.882-1.248,11.364c1.596,1.992,3.943,3.027,6.311,3.027
				c1.774,0,3.561-0.581,5.053-1.777c22.474-18.022,35.364-44.849,35.364-73.599c0-28.75-12.89-55.576-35.362-73.599
				c-3.482-2.795-8.571-2.233-11.364,1.248c-2.794,3.483-2.234,8.571,1.248,11.364c18.627,14.938,29.31,37.167,29.31,60.986
				C441.937,252.872,431.254,275.1,412.626,290.038z"/>
                        <path  stroke="#e0005a" strokeWidth="20" d="M483.302,137.925c-18.346-26.18-43.765-46.03-73.508-57.409c-4.167-1.594-8.843,0.49-10.439,4.662
				c-1.594,4.17,0.493,8.844,4.662,10.439c54.918,21.006,91.815,74.63,91.815,133.435c0,58.805-36.897,112.429-91.815,133.435
				c-4.169,1.594-6.257,6.268-4.662,10.439c1.232,3.22,4.299,5.198,7.552,5.198c0.961,0,1.937-0.172,2.888-0.536
				c29.745-11.378,55.163-31.229,73.508-57.409C502.077,293.39,512,261.879,512,229.053
				C512,196.226,502.077,164.715,483.302,137.925z"/>
                        <path  stroke="#e0005a" strokeWidth="20" d="M342.232,26.947c-22.289,0-40.421,18.132-40.421,40.421v9.833l-147.915,48.233
				c-15.216,4.963-26.836,16.496-32.149,30.861H72.758C32.639,156.295,0,188.933,0,229.053c0,40.119,32.639,72.758,72.758,72.758
				h4.219l33.624,159.706c2.87,13.638,15.069,23.536,29.006,23.536h57.959c16.344,0,29.642-13.298,29.642-29.642v-98.833
				l74.602,24.327v9.833c0,22.289,18.132,40.421,40.421,40.421c22.289,0,40.421-18.132,40.421-40.421V67.368
				C382.653,45.08,364.52,26.947,342.232,26.947z M83.381,285.642H72.758c-31.203,0-56.589-25.387-56.589-56.589
				c0-31.203,25.387-56.589,56.589-56.589h45.836c-0.017,0.548-0.026,1.097-0.026,1.648v109.883c0,0.55,0.008,1.101,0.026,1.648
				H83.708C83.599,285.64,83.49,285.64,83.381,285.642z M211.039,351.305v104.105c0,7.43-6.044,13.474-13.474,13.474h-57.959
				c-6.335,0-11.88-4.499-13.185-10.698L93.5,301.811h28.246c5.313,14.365,16.934,25.899,32.149,30.861l57.145,18.634H211.039z
				 M301.811,363.898l-142.902-46.601c-14.458-4.715-24.172-18.099-24.172-33.305V174.111c0-15.206,9.714-28.59,24.172-33.306
				l142.902-46.599V363.898z M366.484,390.737c0,13.372-10.88,24.253-24.253,24.253s-24.253-10.88-24.253-24.253v-15.513
				c0.003-0.126,0.003-0.25,0-0.374V83.258c0.003-0.125,0.003-0.249,0-0.374V67.368c0-13.372,10.88-24.253,24.253-24.253
				s24.253,10.88,24.253,24.253V390.737z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ProCodeSvgActive;
