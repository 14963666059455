import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Card, Col, Modal, Radio, Row, Select, Space, Spin } from 'antd';
import DateComponent from '../../components/date/DateComponent';
import CorrectSvg from '../../assets/images/CorrectSvg';
import InputNumberComponent from '../../components/input/InputNumberComponent';
import TextAreaComponent from '../../components/input/TextAreaComponent';
import UserProSvg from '../../assets/images/UserProSvg';
import { getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';
import { MEMBER_SUBSCRIPTION_URL, MEMBER_URL } from '../../api/URLs';
import { useLocation, useNavigate } from 'react-router-dom';
import EmptyImageSvg from '../../assets/images/EmptyImageSvg';
import { formatDateTime } from '../../utils/dateFormatUtils';
import HeaderDetailComponent from '../../components/header/headerDetailComponent';
import InputMask from 'react-input-mask';
import { addDays, format } from 'date-fns';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import emptyImage from "../../assets/images/image.jpeg"
import DateRangeComponent from '../../components/date/DateRangeComponent';

function UserDetailPage({ modalOpen, setModalOpen }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [btnDisable, setBtnDisable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [modalOpenNew, setModalOpenNew] = useState(false)
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false)
  const [isChange, setIsChange] = useState(false);
  const messageModalRef = useRef("");
  const params = new URLSearchParams(location.search);
  const [dates, setDates] = useState([null, null]);
  let userDetailId = params.get('id');
  const [dataObj, setDataObj] = useState({
    setMembership: "manually",
    remark: "",
    manually: "",
    manuallyDate: ""
  })
  const [dataObjectNew, setDataObjectNew] = useState({
    phone: {
      countryCode: "852",
      number: ""
    },
  });

  const onChange = (e) => {
    setDataObj((membership) => ({
      ...membership,
      setMembership: e.target.value,
    }));
  };
  useEffect(() => {
    if ((dataObj.manually || dates[0] || dates[1]) && dataObj.remark) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [dataObj]);

  const addNewValue = async (data) => {
    setDataObjectNew({
      phone: {
        countryCode: `${data && data.phone && data.phone.countryCode}`,
        number: data && data.phone && data.phone.number,
      },
    })
  }

  useEffect(() => {
    addNewValue(userDetails && userDetails)
  }, [userDetails])

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    try {
      const memberPayload = {
        phone: {
          countryCode: dataObjectNew && dataObjectNew.phone && dataObjectNew.phone.countryCode,
          number: isChange && typeof dataObjectNew?.phone?.number === 'string'
            ? dataObjectNew.phone.number.replace(/[^0-9]/g, '')
            : dataObjectNew?.phone?.number || '',
        },
      }
      setLoading(true);
      await putDataRequest(`${MEMBER_URL}/${userDetailId} `, memberPayload)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            setTimeout(() => {
              setModalOpenNew(false)
              fetchUserDetails(userDetailId)
            }, 2000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err)
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message || err && err.message, () => { }, "", true);
        });
    } catch (error) {
      console.log("error", error)
      setLoading(false);
    }
  }

  const fetchUserDetails = async (userDetailId) => {
    try {
      setLoading(true); // Start loading
      const memberData = await getDataRequest(`${MEMBER_URL}/${userDetailId}`);
      if (memberData.status === 200) {
        setUserDetails(memberData.data); // Update user details
      }
    } catch (err) {
      console.error("Error fetching user details:", err);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    if (userDetailId) {
      fetchUserDetails(userDetailId); // Call the function when `userDetailId` changes
    }
  }, [userDetailId]);


  const phoneData = [
    {
      label: "+852",
      value: "852"
    },
    {
      label: "+86",
      value: "86"
    }
  ]

  const currentDate = new Date()
  function calculateDatePayload(startDate, daysToAdd) {
    const targetDate = addDays(new Date(startDate), daysToAdd);
    const formattedDate = format(targetDate, 'yyyy-MM-dd HH:mm');
    return formattedDate;
  }

  const handleSubmitMembership = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    try {
      const memberPayload = {
        memberId: userDetailId,
        period: {
            startTS: dataObj.setMembership === "manually" ? formatDateTime(currentDate): formatDateTime(dates[0]),
            endTS: dataObj.setMembership === "manually" ? calculateDatePayload(currentDate, dataObj.manually) : formatDateTime(dates[1]),
        },
        remark: dataObj.remark
      }
      setLoading(true);
      await postDataRequest(`${MEMBER_SUBSCRIPTION_URL}`, memberPayload)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            setTimeout(() => {
              setModalOpen(false)
              fetchUserDetails(userDetailId)
              setDataObj({
                setMembership: "manually",
                remark: "",
                manually: "",
                endTS: "",
              });
              setDates("")
            }, 2000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err)
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message || err && err.message, () => { }, "", true);
        });
    } catch (error) {
      console.log("error", error)
      setLoading(false);
    }
  }
  const address = `${userDetails?.province || ""} ${userDetails?.city || ""}`.trim();

  return (
    <HeaderDetailComponent
      onClick1={() => setModalOpen(true)}
      bntTitle={t('Modify Information')}
      bntTitle1={t('Manually Activate Membership')}
      backgroundColor="#f8e2e8"
      border="1px solid #e72158"
      isHide="view"
      onClick={() => navigate('/member')}
      backTitle={t("User Details")}
      hideTitle="userDetails"
      modalOpenNew={modalOpenNew}
      setModalOpenNew={setModalOpenNew}
    >
      <Spin spinning={loading}>
        <div className='container newDetail'>
          <p className='userTitle'>{t("Basic Information")}</p>
          <Card style={{ width: "100%" }}>
            <div className='d-flex'>
              <Avatar
                src={userDetails && userDetails.picUrl ? userDetails.picUrl : emptyImage}
                style={{
                  height: "48px",
                  width: "48px"
                }}
              >
                {userDetails && !userDetails.picUrl ? !userDetails.picUrl : emptyImage}
              </Avatar>
              {
                userDetails && userDetails.memberType === "trial" ?
                  <Row align="middle" style={{ marginLeft: "1rem" }}>
                    <Col>
                      <CorrectSvg />
                    </Col>
                    <Col className="text-capitalize">{t("Membership trial")}</Col>
                  </Row> : ""
              }
            </div>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('User Id')}:</Col>
              <Col className="text-capitalize">{userDetails && userDetails.memberId}</Col>
            </Row>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('Phone Number')}:</Col>
              <Col className="text-capitalize">{`+${userDetails && userDetails.phone && userDetails.phone.countryCode}`} {userDetails && userDetails.phone && userDetails.phone.number}</Col>
            </Row>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('fullName')}:</Col>
              <Col className="text-capitalize">{userDetails && userDetails.name ? userDetails && userDetails.name : "N/A"}</Col>
            </Row>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('gender')}:</Col>
              <Col className="text-capitalize">{userDetails && userDetails.sex ? t(userDetails && userDetails.sex) :"N/A"}</Col>
            </Row>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('address')}:</Col>
              <Col className="text-capitalize">{address ? address : "N/A"}</Col>
            </Row>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('user category')}:</Col>
              <Col className="text-capitalize">{userDetails && userDetails.category ? t(userDetails && userDetails.category) : "N/A"}</Col>
            </Row>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('Register Time')}:</Col>
              <Col className="text-capitalize">{userDetails && formatDateTime(userDetails.createdTS)}</Col>
            </Row>
            <Row align="middle" className='mt-3'>
              <Col span={6} className='userLabel'>{t('Last Login Time')}:</Col>
              <Col className="text-capitalize">{userDetails && formatDateTime(userDetails.lastLogin)}</Col>
            </Row>
          </Card>
          {
            userDetails && userDetails.memberType === "trial" ?
              <>
                <p className='userTitle pt-3'>{t("Member Information")}</p>
                <Card style={{ width: "100%" }}>
                  <Row align="middle">
                    <Col span={6} className='userLabel'>{t('Membership trial time')}:</Col>
                    <Col className="text-capitalize">{userDetails && userDetails.subscription && formatDateTime(userDetails.subscription.startedTS)}</Col>
                  </Row>
                  <Row align="middle" className='mt-3'>
                    <Col span={6} className='userLabel'>{t('Membership expired date')}:</Col>
                    <Col className="text-capitalize">{userDetails && userDetails.subscription && formatDateTime(userDetails.subscription.endedTS)}</Col>
                  </Row>
                </Card>
              </> : ""
          }
        </div>
        <Modal
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <UserProSvg />
              <span style={{ marginLeft: "10px" }}>{t('Manually Activate Membership')}</span>
            </div>
          }
          centered
          width={700}
          open={modalOpen}
          className='custom-modal'
          onOk={() => setModalOpen(false)}
          onCancel={() => {
            setModalOpen(false)
            setDataObj({
              setMembership: "manually",
              remark: "",
              manually: "",
              endTS: "",
            });
            setDates("")
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <Row className='d-flex align-items-center' style={{ paddingLeft: "80px", paddingTop: "20px", paddingBottom: "15px" }}>
            <Col md={3}>
              <Avatar
                src={userDetails && userDetails.picUrl ? userDetails.picUrl : emptyImage}
                style={{
                  height: "60px",
                  width: "60px"
                }}
              />
            </Col>
            <Col md={16}>
              <Row>
              {
                userDetails && userDetails.memberType === "trial" ?
                <Col md={24}><CorrectSvg /> <span>{t("Membership trial")}</span> </Col> :""}
                <Col md={24} className='mt-2'>
                  <span className='userLabel'>{t('User Id')}:</span>
                  <span style={{ marginLeft: "20px" }}>{userDetails && userDetails.memberId}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className='mt-3' style={{ borderTopColor: "#636E88", borderTopStyle: "dashed", borderTopWidth: "1.5px", height: "20px" }}></div>
          <Row style={{ paddingLeft: "80px", paddingTop: "20px", paddingBottom: "15px", paddingRight: "70px" }}>
            <Col md={7}>{t('To manually set the membership period')}:</Col>
            <Col md={14} style={{ marginTop: "-8px" }}>
              <Row>
                <Col md={24}>
                  <Radio.Group onChange={onChange} value={dataObj.setMembership}>
                    <Space direction="vertical">
                      <Radio value="manually" style={{ width: "150px" }}><InputNumberComponent disabled={dataObj.setMembership === "manuallyDate" ? true : false} value={dataObj.manually} setValue={(value) => setDataObj({ ...dataObj, manually: value })} style={{ width: "100px", marginLeft: "10px" }} placeholder={t('Please select the number of days')} /></Radio>
                      <Radio value="manuallyDate" className='mt-3'>
                        <DateRangeComponent
                            style={{ width: "250px", marginLeft: "10px" }}
                            placeholder={t("Please select a time or time period")}
                            value={dates ? dates : [null, null]}
                            disabled={dataObj.setMembership === "manually" ? true : false}
                            setValue={setDates}
                          />
                        </Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col md={3} style={{ marginLeft: "-130px" }}>{t('day')}</Col>
            <Col md={7} className='mt-4'>{t('Remark')}:</Col>
            <Col md={17} className='mt-4'>
              <Col md={24}>
                <TextAreaComponent value={dataObj.remark} setValue={(value) => setDataObj({ ...dataObj, remark: value })} />
              </Col>
            </Col>
          </Row>
          <div className='d-flex justify-content-center mt-3'>
            <Button
              disabled={btnDisable ? false : true}
              style={{
                backgroundColor: "#E50A47",
                border: "none",
                color: "White",
                height: "45px",
                width: "230px",
                margin: "auto",
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: 500,
                opacity: btnDisable ? 1 : 0.5
              }}
              onClick={handleSubmitMembership}
            >
              {t("Confirm Submit")}
            </Button>
          </div>
        </Modal>
      </Spin>
      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <UserProSvg /> */}
            <span style={{ marginLeft: "10px" }}>{t('Edit Basic Info')}</span>
          </div>
        }
        centered
        width={700}
        open={modalOpenNew}
        className='custom-modal'
        onOk={() => setModalOpenNew(false)}
        onCancel={() => setModalOpenNew(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Row className='mt-3 d-flex align-items-center' style={{ padding: "10px" }}>
          <Col md={4}>
            <div>
              <label htmlFor=''>{t('Phone Number')}</label>
            </div>
          </Col>
          <Col md={20} className='d-flex'>
            <Select
              className="selectCus"
              value={dataObjectNew.phone.countryCode}
              size="large"
              style={{
                width: 100,
              }}
              onChange={(value) => setDataObjectNew({
                ...dataObjectNew,
                phone: {
                  ...dataObjectNew.phone,
                  countryCode: value,
                },
              })}
              options={phoneData}
            />
            <InputMask
              key={dataObjectNew.phone.countryCode}
              value={dataObjectNew.phone.number}
              onChange={(event) => {
                setDataObjectNew({
                  ...dataObjectNew,
                  phone: {
                    ...dataObjectNew.phone,
                    number: event.target.value,
                  }
                });
                setIsChange(true);
              }}
              className={`inputCustomPho ${isSubmit && !dataObjectNew.phone.number ? 'input-error-cus' : ''}`}
              mask={dataObjectNew.phone.countryCode === "852" ? "9999-9999" : "999-9999-9999"}
            />
          </Col>
        </Row>
        <div className='text-center pt-3'>
          <Button
            style={{
              backgroundColor: "#e50a47",
              color: "white",
              height: "45px",
              width: "230px",
              fontWeight: 500,
              border: "none",
              fontSize: "16px"
            }}
            onClick={handleUpdate}
          >
            {t('update')}
          </Button>
        </div>
      </Modal>
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </HeaderDetailComponent>
  );
}

export default UserDetailPage;
