import * as React from 'react';

const WeChatSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 0L3.33333 0C1.86056 0 0 1.86056 0 3.33333L0 16.6667C0 18.1394 1.86056 20 3.33333 20H16.6667C18.1394 20 20 18.1394 20 16.6667V3.33333C20 1.86056 18.1394 0 16.6667 0ZM10 15.2293C9.264 15.2298 8.53156 15.1273 7.824 14.9247C7.76047 14.9053 7.69442 14.8954 7.628 14.8953C7.5 14.8953 7.38378 14.9344 7.274 14.998L5.81444 15.8404C5.77378 15.8639 5.73444 15.8817 5.68611 15.8817C5.65692 15.8817 5.62801 15.8759 5.60104 15.8648C5.57406 15.8536 5.54955 15.8373 5.5289 15.8166C5.50826 15.796 5.49188 15.7715 5.4807 15.7445C5.46953 15.7175 5.46378 15.6886 5.46378 15.6594C5.46378 15.6046 5.48578 15.5497 5.49978 15.4971L5.80022 14.3764C5.813 14.3296 5.82356 14.2842 5.82356 14.235C5.82359 14.1641 5.80667 14.0943 5.77422 14.0313C5.74177 13.9682 5.69472 13.9139 5.637 13.8728C4.23144 12.8546 3.33333 11.3479 3.33333 9.67367C3.33333 6.60544 6.318 4.11811 10 4.11811C12.25 4.11811 14.2384 5.04811 15.4456 6.47078L8.71033 9.46856C8.61321 9.50436 8.50905 9.51688 8.40621 9.50511C8.30338 9.49333 8.20474 9.4576 8.11822 9.40078L6.68611 8.38122C6.65306 8.35653 6.6138 8.34153 6.57271 8.33789C6.53162 8.33426 6.49033 8.34213 6.45346 8.36063C6.41659 8.37912 6.38559 8.40752 6.36395 8.44263C6.3423 8.47775 6.33085 8.51819 6.33089 8.55944C6.33089 8.59122 6.339 8.62389 6.35211 8.653L7.56578 11.316L7.59489 11.38C7.65077 11.48 7.74312 11.5544 7.85264 11.5879C7.96216 11.6213 8.08037 11.6111 8.18256 11.5594L8.23122 11.5312L15.9072 7.09944C16.3913 7.86922 16.6667 8.74456 16.6667 9.67367C16.6667 12.742 13.6819 15.2293 10 15.2293Z" fill="#24B340" />
        </svg>
    );
}

export default WeChatSvg;