export const ApplicationRight = {
  
  // New Access
  Member_Access: '用户管理',//User Management
  Payment_Access: '收款管理',//Payment Management
  Magazine_Access: '杂志管理',//Magazine Management
  News_Posts_Access: '资讯管理',//New Management
  Promotion_Access: '促销管理',//Promotion Management
  Staff_Management_Access: '账户管理',//Staff Management
  System_Setting_Access: 'System_Setting_Access',
};
