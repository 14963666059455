import React, { useEffect, useState } from 'react';
import { Input, Button, Space, Divider } from 'antd';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import UploadSvg from '../../assets/images/uploadSvg';
import { useNavigate } from 'react-router-dom';
import DateRangeComponent from '../../components/date/DateRangeComponent';
import { deleteDataRequest, getDataRequest, postDataRequest } from '../../api/serviceMethods';
import { TAGS_URL } from '../../api/URLs';
import TagMagazineModal from '../../components/magazine/TagMagazineModal';

const MagazineHeader = ({
    setViewType, setModalOpen,
    modalOpen, dates, setDates,
    loading, setTableParams, setLoading,
    tableParams, initLoadMagazine,
    setKeyword, keyword, messageModalRef
}) => {
    const [isListView, setIsListView] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [newTag, setNewTag] = useState("");
    const [tags, setTags] = useState([]);
    const [loadingNew, setLoadingNew] = useState(false);

    const getDataRequestAll = async (value) => {
        setLoading(true)
        await getDataRequest(`${TAGS_URL}`, {
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        }).then((res) => {
            if (res.status === 200) {
                let datas = res?.data ? res?.data.map((it, i) => ({
                    ...it,
                    name: it.name || "",
                })) : []
                setTags(datas);
            }
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                },
            });
            setLoading(false);
        }).catch((err) => {
            setLoading(false)
            console.log("Error", err)
        });
    }
    useEffect(() => {
        // modalOpen && getDataRequestAll()
        getDataRequestAll()
    }, [modalOpen])

    const handleCreate = async (name) => {
        try {
            setLoadingNew(true);
            await postDataRequest(`${TAGS_URL}`, { name: name })
                .then((res) => {
                    if (res.status === 200) {
                        setNewTag("");
                        messageModalRef.current.showSuccessConfirmsAutoClose(t(res && res.message), () => { }, "", true);
                        getDataRequestAll('');
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000);
                    } else {
                        messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
                    }
                    setLoadingNew(false);
                })
                .catch((err) => {
                    setLoadingNew(false);
                    messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
                });
        } catch (error) {
            setLoadingNew(false)
            messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
        }
    }

    const handleAddTag = () => {
        if (newTag.trim() !== "") {
            let filterData = tags.filter(it => it.name === newTag.trim())
            if (filterData.length > 0) {
                messageModalRef.current.showWarningConfirmsAutoClose(t('The tag has exited!'), () => { }, "", true);
                return false
            }
            handleCreate(newTag.trim())
        }
    };

    const handleDeleteTag = (tag, i) => {
        if (tag._id) {
            messageModalRef.current.showWarningConfirm(t('Are you sure you want to delete this tag?'), async () => {
                setLoadingNew(true)
                await deleteDataRequest(`${TAGS_URL}/${tag._id}`).then((res) => {
                    messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
                    getDataRequestAll('');
                    setLoadingNew(false)
                }).catch((err) => {
                    messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
                    setLoadingNew(false)
                });
            }, "", true);
        } else {
            setTags(tags.filter((t) => t !== tag));
        }
    };

    return (
        <>
            <Space
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
                size="small"
            >
                <Input
                    placeholder={t("Search")}
                    size="large"
                    disabled={loading}
                    loading={loading}
                    className="me-4"
                    allowClear
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                />
                <DateRangeComponent
                    placeholder={t("Please select a time or time period")}
                    disabled={loading}
                    value={dates ? dates : [null, null]}
                    setValue={setDates}
                />
                <Button
                    size="large"
                    onClick={() => {
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                current: 1,
                            },
                        });
                        initLoadMagazine();
                    }}
                >
                    {t('Query')}
                </Button>
                <Divider type="vertical" style={{ height: '24px', borderColor: '#d9d9d9' }} />

                <Space size="small">
                    <Button
                        className='newStyleTabs'
                        type={isListView ? 'text' : 'default'}
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                            setIsListView(false)
                            setViewType('card')
                        }}
                        style={{ padding: 0, color: isListView ? '#636E88' : '#E50A47' }}
                    />
                    <Button
                        type={isListView ? 'default' : 'text'}
                        icon={<BarsOutlined />}
                        onClick={() => {
                            setIsListView(true)
                            setViewType('table')
                        }}
                        style={{ padding: 0, color: isListView ? '#E50A47' : '#636E88' }}
                    />
                </Space>
                <Divider type="vertical" style={{ height: '24px', borderColor: '#d9d9d9' }} />
                <Button
                    // type="text"
                    className=''
                    type="text"
                    onClick={() => setModalOpen(true)}
                    style={{
                        fontSize: '16px',
                        marginRight: "1rem",
                        color: "#e72158",
                        border: "1px solid #e72158"
                    }}
                >
                    {t('tag')}
                </Button>
                <Button
                    icon={<UploadSvg />}
                    className=''
                    // type="primary" 
                    onClick={() => navigate("/magazine/upload")}
                    style={{
                        fontSize: '16px',
                        backgroundColor: "#F8E2E8",
                        border: "none",
                        color: "#E50A47"
                    }}
                    danger
                >
                    {t('Upload Magazine')}
                </Button>
            </Space>
            <TagMagazineModal 
              setModalOpen={setModalOpen} 
              modalOpen={modalOpen} 
              setTags={setTags}
              setNewTag={setNewTag} 
              handleAddTag={handleAddTag} 
              tags={tags} 
              handleDeleteTag={handleDeleteTag}
              loadingNew={loadingNew} 
              setLoadingNew={setLoadingNew}
              newTag={newTag}
            />
        </>
    );
};

export default MagazineHeader;
