import { Button, Col, Input, Modal, Row, Spin, Tag } from "antd";
import React from "react";
import AddSvg from "../../assets/images/AddSvg";
import TrashSvg from "../../assets/images/TrashSvg";
import { useTranslation } from "react-i18next";
import TagsSvg from "../../assets/images/TagsSvg";

const TagMagazineModal = (props) => {
    const { setModalOpen, modalOpen, setTags, setNewTag, handleAddTag, tags, handleDeleteTag, loadingNew, newTag } = props
    const { t } = useTranslation();
    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TagsSvg />
                    <span style={{ marginLeft: "10px" }}>{t('tag')}</span>
                </div>
            }
            centered
            width={700}
            open={modalOpen}
            className='custom-modal'
            onOk={() => setModalOpen(false)}
            onCancel={() => {
                setModalOpen(false)
                setTags([])
            }}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <Spin spinning={loadingNew}>
                <hr />
                <div style={{ padding: "20px 60px", marginBottom: "10px" }}>
                    <Row className='d-flex align-items-center'>
                        <Col md={4}>{t("New Label")} :</Col>
                        <Col md={18}>
                            <Input
                                size='large'
                                style={{ marginRight: "8px" }}
                                placeholder=""
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleAddTag(); // Call the function when Enter is pressed
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <Button
                                style={{
                                    backgroundColor: "#F8E2E8",
                                    border: "none",
                                    color: "#E50A47",
                                    height: "26px",
                                    width: "26px",
                                    marginLeft: "10px",
                                    borderRadius: "50%"
                                }}
                                icon={<AddSvg />}
                                onClick={handleAddTag}
                            /></Col>
                    </Row>
                </div>
                <div style={{ borderBottom: "1px dashed #ddd", marginBottom: "16px" }}></div>
                <div className='d-flex align-items-baseline' style={{ padding: "10px 60px", }}>
                    <Col md={4} >{t("Already have tags")} :</Col>
                    <div style={{ marginTop: "8px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        {tags.map((tag, index) => (
                            <Tag
                                key={index}
                                closable
                                onClose={() => handleDeleteTag(tag)}
                                closeIcon={
                                    <div className='d-flex align-items-center'>
                                        <div style={{ height: "18px", width: "1.5px", backgroundColor: "white", marginLeft: "5px", marginRight: "5px" }}></div>
                                        <TrashSvg style={{ cursor: "pointer" }} />
                                    </div>
                                }
                                style={{
                                    backgroundColor: "#fdecf1",
                                    color: "#E50A47",
                                    width: "auto",
                                    height: "25px",
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    fontWeight: 500
                                }}
                            >
                                <div style={{
                                    width: "5px",
                                    height: "5px",
                                    backgroundColor: "#E50A47",
                                    borderRadius: "50%",
                                    marginRight: "5px"
                                }}></div>
                                {tag.name || ""}
                            </Tag>
                        ))}
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-3'>
                    <Button
                        disabled={tags.length > 0 ? false : true}
                        style={{
                            backgroundColor: "#E50A47",
                            border: "none",
                            color: "White",
                            height: "45px",
                            width: "230px",
                            margin: "auto",
                            borderRadius: "10px",
                            fontSize: "16px",
                            fontWeight: 500,
                            opacity: tags.length > 0 ? 1 : 0.5
                        }}
                        onClick={() => {
                            setModalOpen(false)
                        }}
                    >
                        {t("Confirm Submit")}
                    </Button>
                </div>
            </Spin>
        </Modal>
    )
}
export default TagMagazineModal;